import { useState, useEffect } from "react";
import axios from "axios";
import { Flex, Text, Switch } from "@chakra-ui/react";

const TestDetails = () => {
  const [registration, setRegistration] = useState(false);
  const [test, setTest] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const { status, data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/lancet/6150103cc3f82e5d584c3330/detail`
        );
        if (status === 200) {
          setRegistration(data.lancet.registrationStatus);
          setTest(data.lancet.testStatus);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  async function handleRegistrationChange(){
    try {
      const { status, data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/lancet/6150103cc3f82e5d584c3330/change-registration-status`,
        {
          statusValue: !registration,
        }
      );
      if (status === 200) {
         setRegistration(data.savedLancetValue.registrationStatus);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleTestChange() {
    try {
      const { status, data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/lancet/6150103cc3f82e5d584c3330/change-test-status`,
        {
          statusValue: !test,
        }
      );
      if (status === 200) {
        setTest(data.savedLancetValue.testStatus);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Flex w="100%" h="90vh" justify="center" align="center" direction="column">
      <Text fontSize="xl" fontWeight="600">
        Test Details
      </Text>
      <Flex
        w={{ base: "100%", xl: "30%" }}
        align="center"
        p="8"
        bg="blue.100"
        borderRadius="xl"
        mt="4"
        direction="column"
      >
        <Flex align="center" w="100%" justify="space-between">
          <Text>Registrations</Text>
          <Switch
            isChecked={registration ? true : false}
            colorScheme="teal"
            size="lg"
            onChange={handleRegistrationChange}
          />
        </Flex>
        <Flex align="center" w="100%" justify="space-between" mt="4">
          <Text>Test</Text>
          <Switch
            isChecked={test}
            colorScheme="teal"
            size="lg"
            onChange={handleTestChange}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TestDetails;
