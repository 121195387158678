/* eslint-disable */

import React, { useState, useContext, createContext, useEffect } from "react";
import { AdminAuthContextState, Admin } from "./auth.type";
import {
  setupAdminAuthExceptionHandler,
  setupAuthHeaderForServiceCalls,
} from "../../utils/auth-helper-functions";
import { useNavigate } from "react-router-dom";

const initialState: AdminAuthContextState = {
  login: false,
  token: null,
  admin: null,
  setAdmin: () => null,
  setLogin: () => null,
  setToken: () => null,
  setAdminData: () => null,
};

const AdminAuthContext = createContext<AdminAuthContextState>(initialState);

export const AdminAuthProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { isAdminLoggedIn, adminToken } = JSON.parse(
    localStorage?.getItem("lancetAdmin") ||
      JSON.stringify({ isAdminLoggedIn: false, adminToken: null })
  );

  const [login, setLogin] = useState<boolean>(isAdminLoggedIn);
  const [token, setToken] = useState<string | null>(adminToken);
  const [admin, setAdmin] = useState<Admin | null>(null);

  const navigate = useNavigate();

  token && setupAuthHeaderForServiceCalls(token);

  useEffect(() => {
    // setupAdminAuthExceptionHandler(navigate);
  }, []);

  function setAdminData() {}

  return (
    <AdminAuthContext.Provider
      value={{ login, token, admin, setToken, setLogin, setAdminData }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => useContext(AdminAuthContext);
