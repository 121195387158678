/* eslint-disable */

import { useState, useEffect } from "react";
import cn from "classnames";
import { PurpleButton } from "../utils/buttons";
import { InputText, InputSelect } from "../utils/input";
import { ScholarshipFormType } from "../../language/language.types";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import {
  validateEmail,
  validateName,
  validatePhoneNo,
  validateClass,
  validateState,
} from "../utils/validations";

import { useLoading } from "../utils/loader";

const styles = require("./Home.module.css");

const statesAndUts = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman & Nicobar Islands",
  "Dadra and Nagar Haveli and Daman & Diu",
  "Jammu & Kashmir",
  "Lakshadweep",
  "Chandigarh",
  "Delhi",
  "Ladakh",
  "Puducherry",
];

const ScholarshipForm = ({
  formData,
  utmMedium,
  utmSource,
  utmCampaign,
}: {
  formData: ScholarshipFormType | null;
  utmMedium: string | null;
  utmSource: string | null;
  utmCampaign: string | null;
}) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [classStudying, setClassStudying] = useState<string>("");
  const [stateFrom, setStateFrom] = useState<string>("");
  const [termsAgreed, setTermsAgreed] = useState<boolean>(true);

  const [nameValidationMsg, setNameValidationMsg] = useState<string>("");
  const [emailValidationMsg, setEmailValidationMsg] = useState<string>("");
  const [phoneValidationMsg, setPhoneValidationMsg] = useState<string>("");
  const [classValidationMsg, setClassValidationMsg] = useState<string>("");
  const [stateValidationMsg, setStateValiadtionMsg] = useState<string>("");

  const [registrationStatus, setRegistrationStatus] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const { status, data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/lancet/6150103cc3f82e5d584c3330/detail`
        );
        if (status === 200) {
          setRegistrationStatus(data.lancet.registrationStatus);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const toast = useToast();
  const { setLoadingStatus } = useLoading();

  function handleNameInput(e: any) {
    setName(e.target.value);
  }

  function handleEmailInput(e: any) {
    setEmail(e.target.value);
  }

  function handlePhoneInput(e: any) {
    setPhoneNo(e.target.value);
  }

  function handleClassInput(e: any) {
    setClassStudying(e.target.value);
  }

  function handleStateInput(e: any) {
    setStateFrom(e.target.value);
  }

  function handleAgreedTerms(e: any) {
    e.target.checked ? setTermsAgreed(true) : setTermsAgreed(false);
  }

  function checkName() {
    setNameValidationMsg(validateName(name));
  }

  async function checkEmail() {
    setEmailValidationMsg(await validateEmail(email));
  }

  async function checkPhoneNo() {
    setPhoneValidationMsg(await validatePhoneNo(phoneNo));
  }

  function checkClass() {
    setClassValidationMsg(validateClass(classStudying));
  }

  function checkState() {
    setStateValiadtionMsg(validateState(stateFrom));
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    checkName();
    checkEmail();
    checkPhoneNo();
    checkState();
    checkClass();
    if (
      name &&
      email &&
      phoneNo &&
      stateFrom &&
      classStudying &&
      nameValidationMsg === "" &&
      emailValidationMsg === "" &&
      phoneValidationMsg === "" &&
      classValidationMsg === "" &&
      stateValidationMsg === ""
    ) {
      setLoadingStatus("loading");
      submitData(e);
    }
  }

  function submitData(e: any) {
    (async () => {
      e.preventDefault();
      try {
        const { status, data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/student-signup`,
          {
            student: {
              name,
              email,
              mobileNumber: phoneNo,
              state: stateFrom,
              class: classStudying,
              agreedToTerms: termsAgreed,
              source: utmSource,
              medium: utmMedium,
              campaign: utmCampaign,
              registeringFor: "LANCET 4"
            },
          }
        );
        const { status: lhStatus } = await axios.post(
          `https://learnhat.herokuapp.com/api/student/signup`,
          {
            student: {
              name,
              mobileNumber: phoneNo,
              emailId: email,
              class: classStudying,
              source: "LANCET",
              medium: utmMedium,
              campaign: utmCampaign,
              agreedToTerms: termsAgreed,
            },
            source_adv: utmMedium,
          }
        );
        if (status === 200) {
          toast({
            title: "Application submitted, Thanks !!",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          toast({
            title:
              "For further details, please check your sms & email inbox or promotions tab !!",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => {
              setLoadingStatus("done");
              e.target.submit();
            },
          });
        }
      } catch (error) {
        setLoadingStatus("done");
        console.log(error);
        toast({
          title: "Something went wrong!!",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      }
    })();
  }

  return (
    <div className={cn(styles["lancet__form"])}>
      <h1 className={cn(styles["lancet__form-heading"])}>
        {formData && formData["heading"]}
      </h1>
      <form
        id="lancetForm"
        method="post"
        encType="multipart/form-data"
        onSubmit={(e) => handleSubmit(e)}
        action="https://web-in21.mxradon.com/t/FormTracker.aspx"
      >
        <InputText
          id="FirstName"
          name="FirstName"
          maxLength={100}
          value={name}
          autoComplete="off"
          onChange={handleNameInput}
          onBlur={() => checkName()}
          placeholder={(formData && formData["name_placeholder"]) || undefined}
          className="mt-07"
        />
        {nameValidationMsg && (
          <p className="validation-message">{nameValidationMsg}</p>
        )}
        <br />
        <InputText
          id="EmailAddress"
          name="EmailAddress"
          maxLength={100}
          value={email}
          autoComplete="off"
          onChange={handleEmailInput}
          onBlur={() => checkEmail()}
          placeholder={(formData && formData["email_placeholder"]) || undefined}
          className="mt-07"
        />
        {emailValidationMsg && (
          <p className="validation-message">{emailValidationMsg}</p>
        )}
        <InputText
          id="Phone"
          name="Phone"
          maxLength={10}
          value={phoneNo}
          autoComplete="off"
          onChange={handlePhoneInput}
          onBlur={() => checkPhoneNo()}
          placeholder={
            (formData && formData["number_placeholder"]) || undefined
          }
          className="mt-07"
        />
        {phoneValidationMsg && (
          <p className="validation-message">{phoneValidationMsg}</p>
        )}
        <InputSelect
          id="mx_Class"
          name="mx_Class"
          onChange={handleClassInput}
          onBlur={() => checkClass()}
          className="mt-07"
          value={classStudying}
        >
          <option selected value="" disabled hidden>
            {formData && formData["class_placeholder"]}
          </option>
          {formData &&
            formData["class_options"].map((classOption) => {
              return (
                <option key={classOption} value={classOption}>
                  {classOption}
                </option>
              );
            })}
        </InputSelect>
        {classValidationMsg && (
          <p className="validation-message">{classValidationMsg}</p>
        )}
        <InputSelect
          id="mx_State"
          name="mx_State"
          value={stateFrom}
          onChange={handleStateInput}
          onBlur={() => checkState()}
          className="mt-07"
        >
          <option value="" selected hidden>
            {(formData && formData["state_placeholder"]) || undefined}
          </option>
          {statesAndUts.map((stut) => {
            return (
              <option key={stut} value={stut}>
                {stut}
              </option>
            );
          })}
        </InputSelect>
        {stateValidationMsg && (
          <p className="validation-message">{stateValidationMsg}</p>
        )}
        <span className={cn(styles["tc"])}>
          <label htmlFor="MailingPreferences">
            Customer Agreement and Consent
          </label>
          <br />
          <span className={cn(styles["tc-input"])}>
            <input
              type="checkbox"
              id="MailingPreferences"
              name="MailingPreferences"
              checked={termsAgreed}
              onChange={handleAgreedTerms}
              className="mr-05"
            />
            <label htmlFor="MailingPreferences">
              T&amp;C and Privacy Policy
            </label>
          </span>
          <label htmlFor="MailingPreferences">
            I agree to the{" "}
            <a
              href="https://learnhat.com/terms"
              target="_blank"
              className="color--blue"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://learnhat.com/privacy"
              target="_blank"
              className="color--blue"
            >
              Privacy Policy
            </a>{" "}
            of LearnHat.
          </label>
        </span>
        <br />
        <input type="hidden" id="Source" name="Source" value="LANCET Form" />
        <input
          type="hidden"
          id="mx_Advertisement_Name"
          name="mx_Advertisement_Name"
          value={utmMedium || "web form"}
        />
        <PurpleButton
          id="form-submit-button"
          type="submit"
          btnType="btn--large"
          className="mt-07"
          disabled={!registrationStatus}
        >
          Apply
        </PurpleButton>
        <br />
        <input type="hidden" name="MXHOrgCode" value="49542" />
        <input
          type="hidden"
          name="MXHLandingPageId"
          value="fd7743a6-fe64-11eb-ab12-02c8554500f4"
        />{" "}
        <input type="hidden" name="MXHFormBehaviour" value="0" />
        <input type="hidden" name="MXHFormDataTransfer" value="0" />
        <input type="hidden" name="MXHRedirectUrl" value="/" />
        <input type="hidden" name="MXHAsc" value="1" />
        <input
          type="hidden"
          name="MXHPageTitle"
          value="Masterclass Webinar 1.0"
        />{" "}
        <input type="hidden" name="MXHOutputMessagePosition" value="1" />
        <input type="hidden" name="MXHIsExternallyUsed" value="1" />
      </form>
    </div>
  );
};

export default ScholarshipForm;
