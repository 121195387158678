/* eslint-disable */

import { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import { useTestAuth } from "../auth";
import { TestType } from "./quiz.types";
import {
  Flex,
  Link,
  Text,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useQuiz } from "../quiz";
import { useLoading } from "../../utils/loader";

const QuizHome = () => {
  const [activeTests, setActiveTests] = useState<TestType[] | null>(null);
  const [eligibleTest, setEligibleTest] = useState<TestType | null>(null);

  const { login, student, setStudentData } = useTestAuth();
  const { quizState, quizDispatch } = useQuiz();
  const { testId, testTaken } = quizState;
  const { setLoadingStatus } = useLoading();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  function checkTestTakenOrNot() {
    student &&
      eligibleTest &&
      student.testsTaken
        .map((testData) => testData._id)
        .map((test) => {
          test == eligibleTest._id &&
            quizDispatch({ type: "SET_TEST_TAKEN_TRUE" });
        });
  }

  async function addTestTaken() {
    toast({
      title: "Test activated",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    quizDispatch({ type: "SET_TEST_TAKEN_TRUE" });
  }

  useEffect(() => {
    (async function () {
      try {
        setLoadingStatus("loading");
        const { status, data }: AxiosResponse<any> = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/test/active-tests`
        );
        if (status === 200) {
          setActiveTests(data.activeTests);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    login && setStudentData();
  }, [login, eligibleTest]);

  useEffect(() => {
    (function () {
      activeTests?.forEach((testType) => {
        // console.log(student)
        // console.log(testType.forClass)
        if (student && testType.forClass.includes(student.class)) {
          setEligibleTest(testType);
          setLoadingStatus("done");
          checkTestTakenOrNot();
          quizDispatch({
            type: "SET_TESTID",
            payload: { testId: testType._id },
          });
          quizDispatch({
            type: "SET_TEST_DATE",
            payload: { testDate: testType.date }
          })
        }
      });
    })();
  }, [activeTests, student]);

  useEffect(() => {
    checkTestTakenOrNot();
  }, [eligibleTest]);

  useEffect(() => {
    console.log(testId);
    console.log(eligibleTest);
    console.log(testId === eligibleTest?._id);
    quizDispatch({ type: "CLEAR_SCORES" });
  }, [testId]);

  return (
    <Flex
      width="100%"
      h="90vh"
      justify="center"
      align="center"
      direction="column"
      p="4"
    >
      <Text fontWeight="500" mb="4" fontSize="xl">
        Your Test
      </Text>
      {eligibleTest && (
        <Flex
          width={{ base: "100%", xl: "30%" }}
          p="8"
          direction="column"
          justify="center"
          align="center"
          bg="purple.100"
          borderRadius="xl"
        >
          <Text mb="4">{eligibleTest && eligibleTest.name}</Text>
          <Text mb="4">{eligibleTest && eligibleTest.date}</Text>
          {testTaken ? (
            <Button disabled>Test Taken</Button>
          ) : (
            <Button onClick={onOpen}>Start Test</Button>
          )}
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Before Moving Forward</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              On clicking yes, you will be marking this test as started and
              cannot step back afterwards! So make sure you are ready then only
              move forward.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Link to="/test/quiz/start" as={RouterLink}>
              <Button colorScheme="blue" onClick={addTestTaken}>
                Yes, Move Forward
              </Button>
            </Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default QuizHome;
