import { QuizContextStateType, QUIZ_ACTION_TYPE } from "./quiz.types";

export default function quizReducer(
  state: QuizContextStateType,
  action: QUIZ_ACTION_TYPE
) {
  switch (action.type) {
    case "LOAD_DATA":
      return {
        ...state,
        quizQuestions: action.payload.data,
        scoreData: {
          ...state.scoreData,
          outOfScore: action.payload.data
            .filter((question) => question.questionType === "objective")
            .reduce(
              (acc, question) => (acc = acc + Number(question.correctPoint)),
              0
            ),
        },
      };
    case "INCREASE_QUIZ_SCORE":
      return {
        ...state,
        scoreData: {
          ...state.scoreData,
          score: Number(state.scoreData.score) + Number(action.payload.point),
        },
        analytics: { ...state.analytics, correct: state.analytics.correct + 1 },
      };
    case "DECREASE_QUIZ_SCORE":
      return {
        ...state,
        scoreData: {
          ...state.scoreData,
          score:
            Number(state.scoreData.score) -
            Number(action.payload.negativePoint),
        },
        analytics: {
          ...state.analytics,
          incorrect: state.analytics.incorrect + 1,
        },
      };
    case "SKIP_QUESTION":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          unattempted: state.analytics.unattempted + 1,
        },
        userAnswers: [...state.userAnswers, "skipped"],
      };
    case "SET_TESTID":
      return {
        ...state,
        testId: action.payload.testId,
      };
    case "RECORD_USER_ANSWERS":
      return {
        ...state,
        userAnswers: [...state.userAnswers, action.payload.userAnswer],
      };
    case "SET_TIME_TAKEN":
      return {
        ...state,
        scoreData: { ...state.scoreData, timeTaken: action.payload.timeTaken },
      };
    case "SET_TEST_TAKEN_TRUE":
      return {
        ...state,
        testTaken: true,
      };
    case "SET_TEST_DATE":
      return {
        ...state,
        testDate: action.payload.testDate,
      };
    case "CLEAR_SCORES":
      return {
        ...state,
        scoreData: {
          outOfScore: 0,
          score: 0,
          timeTaken: "",
        },
        userAnswers: [],
        analytics: {
          correct: 0,
          incorrect: 0,
          unattempted: 0,
        },
      };
    default:
      return state;
  }
}
