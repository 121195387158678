import cn from "classnames";
import { useLanguage } from "../../language";
import ScholarshipForm from "./ScholarshipForm";
import ScholarshipForm2 from "./ScholarshipForm2";
import ScholarshipForm3 from "./ScholarshipForm3";
import { ScholarshipCard } from "../utils/cards";
import { useLocation } from "react-router-dom"

const styles = require("./Home.module.css");

const HomeAbout = ({
  utmMedium,
  utmSource,
  utmCampaign,
}: {
  utmMedium: string | null;
  utmSource: string | null;
  utmCampaign: string | null;
}) => {
  const { languageData } = useLanguage();

  const { pathname } = useLocation()

  const { form, why_lancet, about_lancet } = languageData || {
    form: null,
    why_lancet: null,
    about: null,
  };

  return (
    <div className={cn(styles["lancet__about"])}>
      <div className={cn(styles["lancet__formandwhy"])}>
        <div className={cn(styles["lancet__why"])}>
          <h1 className={cn(styles["lancet__why-heading"])}>
            {why_lancet && why_lancet["heading"]["title"]}
            <span className="color--blue m-05">
              {why_lancet && why_lancet["heading"]["highlight"]}
            </span>
          </h1>
          <div className={cn(styles["lancet__why-cards"])}>
            {why_lancet &&
              why_lancet["cards"].map(({ icon, top_text, bottom_text }) => {
                return (
                  <ScholarshipCard
                    key={icon}
                    icon={icon}
                    top_text={top_text}
                    bottom_text={bottom_text}
                  />
                );
              })}
          </div>
          <div className={cn(styles["lancet__offer"])}>
            <img
              src="https://res.cloudinary.com/learnhat/image/upload/v1629218576/website_images/scholarship-girl.png"
              alt={"scholarship-girl"}
            />
            <div className={styles["lancet__offer-detail"]}>
              <p className={cn(styles["lancet__offer-heading"])}>
                {why_lancet && why_lancet.offers.title}
              </p>
              <div className={cn(styles["lancet__offer-cards"])}>
                {why_lancet &&
                  why_lancet.offers.numbers.map((number) => {
                    return <OfferCard key={number} number={number} />;
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className={cn(styles["lancet__formcontainer"])}>
          {pathname === "/noemail" ? (
            <ScholarshipForm2
              formData={form && form}
              utmMedium={utmMedium}
              utmSource={utmSource}
              utmCampaign={utmCampaign}
            />
          ) : pathname === "/pmkvy" ? (
            <ScholarshipForm3
              formData={form && form}
              utmMedium={utmMedium}
              utmSource={utmSource}
              utmCampaign={utmCampaign}
            />
          ) : (
            <ScholarshipForm
              formData={form && form}
              utmMedium={utmMedium}
              utmSource={utmSource}
              utmCampaign={utmCampaign}
            />
          )}
        </div>
      </div>
      <div className="mt-2">
        <h1 className={cn(styles["lancet__detail-heading"])}>
          {about_lancet && about_lancet["heading"]["normal"]}
          <span className="color--blue m-05">
            {about_lancet && about_lancet["heading"]["highlight_text"]}
          </span>
        </h1>
        <div className={cn(styles["lancet__detail-cards"])}>
          {about_lancet &&
            about_lancet["cards"].map(({ icon, title, data, color }) => {
              return (
                <ScholarshipDetailCard
                  icon={icon}
                  title={title}
                  data={data}
                  color={color}
                  key={icon}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

const OfferCard = ({ number }: {number: string}) => {
  return (
    <div className={cn(styles["lancet__offer-card"])}>
      <img
        src="https://res.cloudinary.com/learnhat/image/upload/v1629218612/website_images/icons/star.png"
        alt={`${number}`}
        width="100%"
      />
      <p className={cn(styles["lancet__offer-number"])}>{number}</p>
    </div>
  );
};

const ScholarshipDetailCard = ({
  icon,
  title,
  data,
  color,
}: {
  icon: string;
  title: string;
  data: string;
  color: string;
}) => {
  return (
    <div className={cn(styles["lancet__detail-card"], `bgcolor--${color}`)}>
      <h1>{title}</h1>
      <img
        src={`https://res.cloudinary.com/learnhat/image/upload/v1629218612/website_images/icons/${icon}.png`}
        alt={icon}
      />
      <p>{data}</p>
    </div>
  );
};

export default HomeAbout;
