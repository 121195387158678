import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Home } from "./components";
import ReactGA from "react-ga";
import { FullScreenLoader } from "./components/utils/loader";
import {
  Admin,
  AdminHome,
  AdminLogin,
  CMSHome,
  ProtectedAdminRoute,
  CMS,
  QuestionsUpload,
} from "./components/admin";
import {
  LancetTest,
  ProtectedTestRoute,
  Quiz,
  StudentLogin,
  TestHome,
  QuizHome,
  QuizTestHome,
} from "./components/lancetTest";
import { StudentVerifyOTP } from "./components/lancetTest/auth";
import { TestDetails } from "./components/admin/cms";

function initializeReactGA() {
  ReactGA.initialize("UA-205597240-1");
  ReactGA.pageview("/homepage");
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  useEffect(() => {
    initializeReactGA();
  }, []);

  const query = useQuery();

  const utmMedium = query.get("utm_medium");
  const utmSource = query.get("utm_source");
  const utmCampaign = query.get("utm_campaign");

  return (
    <div className="App">
      <FullScreenLoader />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              utmMedium={utmMedium}
              utmSource={utmSource}
              utmCampaign={utmCampaign}
            />
          }
        />
        <Route
          path="/noemail"
          element={
            <Home
              utmMedium={utmMedium}
              utmSource={utmSource}
              utmCampaign={utmCampaign}
            />
          }
        />
        <Route
          path="/pmkvy"
          element={
            <Home
              utmMedium={utmMedium}
              utmSource={utmSource}
              utmCampaign={utmCampaign}
            />
          }
        />
        <Route path="/admin" element={<Admin />}>
          <Route path="/" element={<AdminHome />} />
          <Route path="/login" element={<AdminLogin />} />
          <ProtectedAdminRoute path="/cms" element={<CMS />}>
            <Route path="/" element={<CMSHome />} />
            <Route path="/questions" element={<QuestionsUpload />} />
            <Route path="/testDetails" element={<TestDetails />} />
          </ProtectedAdminRoute>
        </Route>
        <Route path="/test" element={<LancetTest />}>
          <Route path="/" element={<TestHome />} />
          <Route path="/login" element={<StudentLogin />} />
          <Route path="/verify-otp" element={<StudentVerifyOTP />} />
          <ProtectedTestRoute path="/quiz" element={<Quiz />}>
            <Route path="/" element={<QuizHome />} />
            <Route path="/start" element={<QuizTestHome />} />
          </ProtectedTestRoute>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
