export function validateName(name: string) {
  if (name !== "") {
    let res = /^[a-zA-Z ]+$/.test(name);
    if (res) {
      return "";
    } else {
      return "Numbers not allowed for name";
    }
  } else {
    return "Name is required";
  }
}

function checkEmailFormat(email: string) {
  const res =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      String(email).toLowerCase()
    );
  return res;
}

export async function validateEmail(email: string) {
  if (email !== "") {
    if (checkEmailFormat(email)) {
      return "";
    } else {
      return "Enter a valid email";
    }
  } else {
    return "Email is required";
  }
}

function checkPhoneNoFormat(phoneNo: string) {
  if (phoneNo.length >= 10) {
    const res = /^[0-9]+$/.test(phoneNo);
    return res;
  } else return false;
}

export async function validatePhoneNo(mobileNumber: string) {
  if (mobileNumber !== "") {
    if (checkPhoneNoFormat(mobileNumber)) {
      return "";
    } else {
      return "Enter a valid phone number";
    }
  } else {
    return "Phone number is required";
  }
}

export function validateClass(classStudying: string) {
  if (classStudying !== "") {
    return "";
  } else {
    return "Class is required";
  }
}

export function validateState(stateFrom: string) {
  if (stateFrom !== "") {
    return "";
  } else {
    return "State is required";
  }
}
