import axios from "axios";
import { NavigateFunction } from "../../../../node_modules/react-router/umd/index";

export async function loginAdmin(username: string, password: string) {
  try {
    const { status, data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/login`,
      {
        username,
        password,
      }
    );
    if (status === 200) {
      localStorage?.setItem(
        "lancetAdmin",
        JSON.stringify({ isAdminLoggedIn: true, adminToken: data.token })
      );
      return { loginStatus: true, token: data.token };
    } else {
      return { loginStatus: false, token: null };
    }
  } catch (error) {
    console.log(error);
    return { loginStatus: false, token: null };
  }
}

export async function loginStudent(mobileNumber: string) {
  try {
    const { status, data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/student-login`,
      {
        mobileNumber,
      }
    );
    if (status === 200) {
      console.log(data.success)
      sessionStorage.setItem(
        "lancetStudent",
        JSON.stringify({
          studentToken: data.token,
        })
      );
      return {
        otpStatus: true,
        token: data.token,
        message: data.message,
      };
    } else {
      return { otpStatus: false, token: null, message: data.message };
    }
  } catch (error) {
    console.log(error);
    return { loginStatus: false, token: null };
  }
}

export async function verifyOtp(otp: string) {
  try {
    const { status, data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/student-verify-otp`,
      {
        otp
      }
    );
    if (status === 200) {
      sessionStorage.setItem(
        "lancetStudent",
        JSON.stringify({
          isStudentLoggedIn: true,
          studentToken: data.token,
          storedStudentId: data.student._id,
        })
      );
      return {
        loginStatus: true,
        token: data.token,
        studentId: data.student._id,
      };
    } else {
      return { loginStatus: false, token: null };
    }
  } catch (error) {
    console.log(error);
    return { loginStatus: false, token: null };
  }
}

export async function logoutAdmin() {
  localStorage.removeItem("lancetAdmin");
}

export async function logoutStudent() {
  sessionStorage.clear();
}

export function setupAuthHeaderForServiceCalls(token: string | null) {
  if (token) {
    return (axios.defaults.headers.common["Authorization"] = `Bearer ${token}`);
  }
  delete axios.defaults.headers.common["Authorization"];
}

export function setupAdminAuthExceptionHandler(navigate: NavigateFunction) {
  const UNAUTHORIZED = 401;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === UNAUTHORIZED) {
        navigate("/admin/login");
      }
      return Promise.reject(error);
    }
  );
}

export function setupStudentAuthExceptionHandler(navigate: NavigateFunction) {
  const UNAUTHORIZED = 401;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === UNAUTHORIZED) {
        navigate("/test");
        logoutStudent();
      }
      return Promise.reject(error);
    }
  );
}
