import cn from "classnames";
const styles = require("./Input.module.css");

const InputText = ({
  placeholder,
  onChange,
  required,
  className,
  id,
  name,
  autoComplete,
  maxLength,
  value,
  onBlur
}: {
  placeholder?: string;
  onChange: (e: any) => void;
  required?: boolean;
  className?: string;
  id?: string;
  name?: string;
  autoComplete?: string;
  maxLength?: number;
  value: string | number;
  onBlur?: () => void
}) => {
  return (
    <input
      required={required}
      className={cn(styles.input, className)}
      type="text"
      placeholder={placeholder && placeholder}
      onChange={onChange}
      id={id}
      name={name}
      maxLength={maxLength}
      autoComplete={autoComplete}
      value={value}
      onBlur={onBlur}
    />
  );
};

const InputSelect = ({
  children,
  onChange,
  id,
  name,
  className,
  required,
  value,
  onBlur,
}: {
  children: any;
  onChange: (e: any) => void;
  id: string;
  name: string;
  className?: string;
  required?: boolean;
  value?: string | number;
  onBlur?: () => void;
}) => {
  return (
    <select
      onChange={onChange}
      className={cn(styles.input, styles["input-select"], className)}
      id={id}
      name={name}
      required={required}
      value={value}
      onBlur={onBlur}
    >
      {children}
    </select>
  );
};

export { InputText, InputSelect };
