import { Outlet } from "react-router-dom"
import { AdminNavbar } from "../admin";

const Admin = () => {
    return (
      <>
        <AdminNavbar />
        <div>
          <Outlet />
        </div>
      </>
    );
}

export default Admin
