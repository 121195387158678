import { Route, Navigate } from "react-router-dom";
import { useAdminAuth } from "../auth";

const ProtectedAdminRoute = ({ path, element, ...props }: any) => {
  const { login } = useAdminAuth();
  return login ? (
    <Route {...props} element={element} path={path} />
  ) : (
    <Navigate replace to="/admin/login" state={{ from: path }} />
  );
};

export default ProtectedAdminRoute;
