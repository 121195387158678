import { Link, Flex, Button, Text } from "@chakra-ui/react"

import { Link as RouterLink } from "react-router-dom"

const CMSHome = () => {
    return (
      <Flex
        w="100%"
        h="90vh"
        justify="center"
        align="center"
        direction="column"
        p="4"
      >
        <Text fontSize="xl" fontWeight="600">
          CMS Home
        </Text>
        <Flex
           w={{ base: "100%", xl: "30%" }}
          justify="center"
          height="100px"
          p="8"
          bg="blue.200"
          borderRadius="xl"
          mt="4"
        >
          <Link to="/admin/cms/questions" as={RouterLink}>
            <Button>Question Upload</Button>
          </Link>
        </Flex>
        <Flex
          w={{ base: "100%", xl: "30%" }}
          justify="center"
          height="100px"
          p="8"
          bg="blue.200"
          borderRadius="xl"
          mt="4"
        >
          <Link to="/admin/cms/testdetails" as={RouterLink}>
            <Button>Test Details</Button>
          </Link>
        </Flex>
      </Flex>
    );
}

export default CMSHome
