/* eslint-disable */

import React, { useState, useContext, createContext, useEffect } from "react";
import { TestAuthContextState, Student } from "./auth.type";
import {
  setupStudentAuthExceptionHandler,
  setupAuthHeaderForServiceCalls,
} from "../../utils/auth-helper-functions";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { logoutStudent } from "../../utils/auth-helper-functions";

const initialState: TestAuthContextState = {
  login: false,
  token: null,
  student: null,
  setStudent: () => null,
  setLogin: () => null,
  setToken: () => null,
  setStudentData: () => null,
  setStudentId: () => null,
};

const TestAuthContext = createContext<TestAuthContextState>(initialState);

export const TestAuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { isStudentLoggedIn, studentToken, storedStudentId } = JSON.parse(
    sessionStorage.getItem("lancetStudent") ||
      JSON.stringify({
        isStudentLoggedIn: false,
        studentToken: null,
        storedStudentId: "",
      })
  );

  const [login, setLogin] = useState<boolean>(isStudentLoggedIn);
  const [token, setToken] = useState<string | null>(studentToken);
  const [studentId, setStudentId] = useState<string>(storedStudentId);
  const [student, setStudent] = useState<Student | null>(null);

  const navigate = useNavigate();

  token && setupAuthHeaderForServiceCalls(token);

  useEffect(() => {
    setupStudentAuthExceptionHandler(navigate);
  }, []);

  useEffect(() => {
    studentId !== "" && setStudentData();
    if (login && token && studentId) {
      sessionStorage.setItem(
        "lancetStudent",
        JSON.stringify({
          isStudentLoggedIn: true,
          studentToken: token,
          storedStudentId: studentId,
        })
      );
    }
  }, [login, token, studentId]);

  async function setStudentData() {
    try {
      if (studentId !== "") {
        const { status, data }: AxiosResponse<any> = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/student/${studentId}/detail`
        );
        if (status === 200) {
          setStudent({ ...data.student });
        }
      }
    } catch (error) {
      console.log(error);
      setLogin(false);
      setToken(null);
      setStudentId("");
    }
  }

  return (
    <TestAuthContext.Provider
      value={{
        login,
        token,
        student,
        setToken,
        setLogin,
        setStudentData,
        setStudentId,
      }}
    >
      {children}
    </TestAuthContext.Provider>
  );
};

export const useTestAuth = () => useContext(TestAuthContext);
