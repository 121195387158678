/* eslint-disable */

import { Box, Flex, Text, Button, Spinner, Textarea } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useQuiz } from "../quiz";

export default function QuizQuestions({ setShowResults, setStartQuiz }: any) {
  const { quizState, quizDispatch } = useQuiz();
  const { quizQuestions } = quizState;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [timer, setTimer] = useState(30 * 60);
  const [optionClicked, setOptionClicked] = useState<string | null>(null);
  const [skipQuestionClicked, setSkipQuestionClicked] =
    useState<boolean>(false);
  const [subjectiveAnswer, setSubjectiveAnswer] = useState("");

  const navigate = useNavigate()

  useEffect(() => {
    if (timer > 0) {
      let timerInterval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => {
        clearInterval(timerInterval);
      };
    }
    if (timer === 0) {
      quizDispatch({ type: "SKIP_QUESTION" });
      setShowResults(true);
      setStartQuiz(false);
    }
  }, [timer]);

  function nextQuestion() {
    if (currentQuestion < 19) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      quizDispatch({
        type: "RECORD_USER_ANSWERS",
        payload: {
          userAnswer: subjectiveAnswer,
        },
      });
      const timeLeft = 30 * 60 - timer;
      quizDispatch({
        type: "SET_TIME_TAKEN",
        payload: { timeTaken: `${Math.floor(timeLeft / 60)}:${timeLeft % 60}` },
      });
      setShowResults(true);
      setStartQuiz(false);
    }
  }

  // function prevQuestion(){
  //   if(currentQuestion > 0){
  //     setCurrentQuestion(currentQuestion - 1)
  //   }
  // }

  function checkForAnswer() {
    quizDispatch({
      type: "RECORD_USER_ANSWERS",
      payload: {
        userAnswer: optionClicked
      },
    });
    if (quizQuestions !== null && quizQuestions.length > 0) {
      if (optionClicked === quizQuestions[currentQuestion].correctAnswer) {
        quizDispatch({
          type: "INCREASE_QUIZ_SCORE",
          payload: { point: quizQuestions[currentQuestion].correctPoint },
        });
      } else {
        quizDispatch({
          type: "DECREASE_QUIZ_SCORE",
          payload: {
            negativePoint: quizQuestions[currentQuestion].negativePoint,
          },
        });
      }
    }
  }

  useEffect(() => {
    return () => {
      setOptionClicked(null);
      setSkipQuestionClicked(false);
    };
  }, [currentQuestion]);

  return (
    <Flex
      w={{ base: "100%", xl: "50%" }}
      direction="column"
      bg={"blue.200"}
      p={4}
      borderRadius="lg"
    >
      {quizQuestions && quizQuestions.length > 0 ? (
        <>
          <Flex w="100%" justify="space-between">
            <Text>Question: {currentQuestion + 1}</Text>
          </Flex>
          <Box
            w="100%"
            borderWidth="1px"
            mt="4"
            borderRadius="lg"
            p={4}
            borderColor={"gray.200"}
          >
            <Box>
              <Text>
                Question: {quizQuestions[currentQuestion].questionText}
              </Text>
            </Box>
            {quizQuestions[currentQuestion].questionType === "objective" ? (
              Object.keys(quizQuestions[currentQuestion].options).map(
                (option) => {
                  return (
                    quizQuestions[currentQuestion].options[option] !== null && (
                      <Button
                        key={option}
                        bg={option === optionClicked ? "blue.600" : "white"}
                        w="100%"
                        p="4"
                        cursor="pointer"
                        my="3"
                        borderRadius="md"
                        onClick={() => {
                          setOptionClicked(option);
                        }}
                        isDisabled={
                          optionClicked || skipQuestionClicked ? true : false
                        }
                        textAlign="left"
                        _hover={{
                          color: option === optionClicked ? "white" : "black",
                          bg: option === optionClicked ? "blue.600" : "white",
                        }}
                        color={option === optionClicked ? "white" : "black"}
                      >
                        {quizQuestions[currentQuestion].options[option] !==
                          null &&
                          quizQuestions[currentQuestion].options[option]}
                      </Button>
                    )
                  );
                }
              )
            ) : (
              <Textarea
                bg="white"
                mt="4"
                h="200px"
                resize="none"
                size="md"
                placeholder="Write your answer here..."
                onChange={(e) => setSubjectiveAnswer(e.target.value)}
              />
            )}
          </Box>
          {optionClicked && (
            <Flex direction="column">
              <Text
                fontWeight="medium"
                textAlign="center"
                fontSize={{ base: "sm" }}
              >
                You have selected{" "}
                <b>
                  Option{" "}
                  {optionClicked.charAt(optionClicked.length - 1).toUpperCase()}
                </b>{" "}
                as your answer.
              </Text>
              <Text fontWeight="medium" textAlign="center">
                Submit this answer and move ahead?
              </Text>
              <Flex
                justify="space-between"
                mt="3"
                direction={{ base: "column", xl: "row" }}
              >
                <Button
                  onClick={() => setOptionClicked(null)}
                  mt={{ base: "3" }}
                >
                  No, I want to change my answer
                </Button>
                <Button
                  onClick={() => {
                    checkForAnswer();
                    nextQuestion();
                  }}
                  mt={{ base: "3" }}
                >
                  Yes, move to next question
                </Button>
              </Flex>
            </Flex>
          )}
          {skipQuestionClicked && (
            <Flex direction="column">
              <Text fontWeight="medium" textAlign="center">
                You have cliked to skip this question.
              </Text>
              <Text fontWeight="medium" textAlign="center">
                Skip this question and move ahead?
              </Text>
              <Flex
                justify="space-between"
                mt="3"
                direction={{ base: "column", xl: "row" }}
              >
                <Button
                  onClick={() => setSkipQuestionClicked(false)}
                  mt={{ base: "3" }}
                >
                  No, I want to try this question
                </Button>
                <Button
                  onClick={() => {
                    quizDispatch({ type: "SKIP_QUESTION" });
                    nextQuestion();
                  }}
                  mt={{ base: "3" }}
                >
                  Yes, skip and move to next question
                </Button>
              </Flex>
            </Flex>
          )}
          <Flex justifyContent="space-between" mt="3" align="center">
            <Text width="120px" fontWeight="bold">
              Time:- {Math.floor(timer / 60)}:{`${timer % 60}`.padStart(2, "0")}
            </Text>
            {/* {currentQuestion > 0 && <Button onClick={prevQuestion}>Prev</Button>} */}
            {currentQuestion >= 19 ? (
              <Button
                onClick={() => {
                  nextQuestion();
                }}
              >
                {`Finish`}
              </Button>
            ) : (
              !optionClicked &&
              !skipQuestionClicked && (
                <Button
                  onClick={() => setSkipQuestionClicked(true)}
                >{`Skip Question`}</Button>
              )
            )}
            {/* {currentQuestion < 19 && <Button>Next</Button>} */}
          </Flex>
        </>
      ) : (
        <>
        {navigate("/test/quiz")}
        </>
      )}
    </Flex>
  );
}
