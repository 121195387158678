import { Flex, Link, Button, Text } from "@chakra-ui/react" 
import { Link as RouterLink } from "react-router-dom"

const TestHome = () => {
    return (
      <Flex
        w="100%"
        h="90vh"
        justify="center"
        align="center"
        direction="column"
      >
        <Text fontSize={{ base: "xl", xl: "4xl" }} fontWeight="500">
          Welcome to Lancet Test Platform
        </Text>
        <Flex bg="blue.200" p="8" mt="4" borderRadius="xl">
          <Link to="/test/quiz" as={RouterLink}>
            <Button p="4" fontSize="xl">
              Go to Test Section
            </Button>
          </Link>
        </Flex>
      </Flex>
    );
}

export default TestHome
