import cn from "classnames"

const styles = require("./Cards.module.css")

const ScholarshipCard = ({
  icon,
  top_text,
  bottom_text,
}: {
  icon: string;
  top_text: {
    normal?: string;
    highlight_black?: string;
    highlight_purple?: string;
  };
  bottom_text: {
    normal?: string;
    highlight_black?: string;
    highlight_purple?: string;
  };
}) => {
  return (
    <div className={cn(styles["scholarship-card"])}>
      <img
        src={`https://res.cloudinary.com/learnhat/image/upload/v1629218612/website_images/icons/${icon}.png`}
        alt={icon}
      />
      <p>
        {top_text.normal && top_text.normal}{" "}
        {top_text["highlight_purple"] && (
          <b className="color--blue">{top_text["highlight_purple"]} </b>
        )}
        {top_text["highlight_black"] && <b>{top_text["highlight_black"]} </b>}
        <br />
        {bottom_text.normal && bottom_text.normal}{" "}
        {bottom_text["highlight_purple"] && (
          <b className="color--blue">{bottom_text["highlight_purple"]} </b>
        )}
        {bottom_text["highlight_black"] && (
          <b>{bottom_text["highlight_black"]} </b>
        )}
      </p>
    </div>
  );
};

export { ScholarshipCard }