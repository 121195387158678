import { Outlet } from "react-router-dom"

const CMS = () => {
    return (
      <div>
        <Outlet />
      </div>
    );
}

export default CMS
