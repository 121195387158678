import {
  Button,
  Flex,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { logoutAdmin } from "../utils/auth-helper-functions";
import { useAdminAuth } from "../admin"

export default function AdminNavbar() {
  const { login, setLogin } = useAdminAuth();

  return (
    <Flex bg="blue.200" px={6} py={4} align="center" justify="space-between">
      <Flex
        w={{ base: "60%", xl: "20%" }}
        justify="space-between"
        align="center"
      >
        <Link fontSize={{ base: "xs", xl: "xl" }} fontWeight="500" as={RouterLink} to="/admin">
          Lancet Admin
        </Link>
      </Flex>
      <Flex
        w={{ base: "25%", xl: "10%" }}
        justify="space-between"
        align="center"
      >
        {login ? (
          <Button
            onClick={() => {
              logoutAdmin();
              setLogin(false);
            }}
          >
            Logout
          </Button>
        ) : (
          <Link as={RouterLink} to="/admin/login" fontWeight="500">
            Login
          </Link>
        )}
      </Flex>
    </Flex>
  );
}
