import { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Input,
  Select,
  Text,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";
import axios, { AxiosResponse } from "axios";
import { TestType } from "./cms.types";
import { useLoading } from "../../utils/loader";

const QuestionsUpload = () => {
  const [questionText, setQuestionText] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [optionA, setOptionA] = useState<string>("");
  const [optionB, setOptionB] = useState<string>("");
  const [optionC, setOptionC] = useState<string>("");
  const [optionD, setOptionD] = useState<string>("");
  const [questionType, setQuestionType] = useState<string>("objective");
  const [correctAnswer, setCorrectAnswer] = useState<string>("");
  const [correctPoint, setCorrectPoint] = useState<string>("1");
  const [negativePoint, setNegativePoint] = useState<string>("0");
  const [selectedTestId, setSelectedTestId] = useState<string>("");
  const [activeTests, setActiveTests] = useState<TestType[] | null>(null);

  const toast = useToast();
  const { setLoadingStatus } = useLoading();

  useEffect(() => {
    (async function () {
      try {
        const { status, data }: AxiosResponse<any> = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/test/active-tests`
        );
        if (status === 200) {
          setActiveTests(data.activeTests);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  function handleQuestiontypeInput(e: any) {
    setQuestionType(e.target.value);
  }

  function handleQuestionTextInput(e: any) {
    setQuestionText(e.target.value);
  }

  function handleOptionAInput(e: any) {
    setOptionA(e.target.value);
  }

  function handleOptionBInput(e: any) {
    setOptionB(e.target.value);
  }

  function handleOptionCInput(e: any) {
    setOptionC(e.target.value);
  }

  function handleOptionDInput(e: any) {
    setOptionD(e.target.value);
  }

  function handlesubjectInput(e: any) {
    setSubject(e.target.value);
  }

  function handleCorrectAnswerInput(e: any) {
    setCorrectAnswer(e.target.value);
  }

  function handleCorrectPointsInput(e: any) {
    setCorrectPoint(e.target.value);
  }

  function handleNegativePointsInput(e: any) {
    setNegativePoint(e.target.value);
  }

  function handleTestSetTypeInput(e: any) {
    setSelectedTestId(e.target.value);
  }

  async function handleSubmit() {
    try {
      setLoadingStatus("loading");
      const { status }: AxiosResponse<any> = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/question/create`,
        {
          question: {
            questionText,
            subject,
            options: {
              option_a: optionA,
              option_b: optionB,
              option_c: optionC,
              option_d: optionD,
            },
            questionType,
            correctAnswer,
            correctPoint,
            negativePoint,
            testId: selectedTestId,
          },
        }
      );
      if (status === 200) {
        setLoadingStatus("done");
        toast({
          title: "Question uploaded !!",
          status: "success",
          duration: 1000,
          isClosable: true,
        });
        clearAll();
      }
    } catch (error) {
      setLoadingStatus("done");
      toast({
        title: "Something went wrong !!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      console.log(error);
    }
  }

  function clearAll() {
    setQuestionText("");
    setOptionA("");
    setOptionB("");
    setOptionC("");
    setOptionD("");
    setCorrectPoint("1");
    setNegativePoint("0");
    setQuestionType("objective");
    setCorrectAnswer("");
    setSubject("");
    setSelectedTestId("");
  }

  return (
    <Flex w="100%" h="90vh" justify="center" align="center">
      <Flex
        p="5"
        direction="column"
        bg="white"
        borderRadius="xl"
        width="70%"
        border="2px"
        borderColor="gray.400"
      >
        <RadioGroup value={questionType} my="2">
          <Stack direction="row">
            <Text>Question type: </Text>
            <Radio value="objective" onChange={handleQuestiontypeInput}>
              Objective
            </Radio>
            <Radio value="subjective" onChange={handleQuestiontypeInput}>
              Subjective
            </Radio>
          </Stack>
        </RadioGroup>
        <Flex align="center" my="2">
          <Text width="15%">Question Text:</Text>
          <Input
            value={questionText}
            onChange={handleQuestionTextInput}
            border="2px"
            borderColor="gray.400"
            placeholder="Enter question text"
          />
        </Flex>
        {questionType === "objective" && (
          <>
            <Flex align="center" my="2">
              <Text width="15%">Option A:</Text>
              <Input
                value={optionA}
                onChange={handleOptionAInput}
                border="2px"
                borderColor="gray.400"
                placeholder="Option A"
              />
            </Flex>
            <Flex align="center" my="2">
              <Text width="15%">Option B:</Text>
              <Input
                value={optionB}
                onChange={handleOptionBInput}
                border="2px"
                borderColor="gray.400"
                placeholder="Option B"
              />
            </Flex>
            <Flex align="center" my="2">
              <Text width="15%">Option C:</Text>
              <Input
                value={optionC}
                onChange={handleOptionCInput}
                border="2px"
                borderColor="gray.400"
                placeholder="Option C"
              />
            </Flex>
            <Flex align="center" my="2">
              <Text width="15%">Option D:</Text>
              <Input
                value={optionD}
                onChange={handleOptionDInput}
                border="2px"
                borderColor="gray.400"
                placeholder="Option D"
              />
            </Flex>
            <Flex justify="space-between">
              <Flex align="center" my="2" w="40%">
                <Text width="50%">Correct Answer:</Text>
                <Select
                  placeholder="Correct Option"
                  border="2px"
                  borderColor="gray.400"
                  onChange={handleCorrectAnswerInput}
                >
                  <option value="option_a">Option A</option>
                  <option value="option_b">Option B</option>
                  <option value="option_c">Option C</option>
                  <option value="option_d">Option D</option>
                </Select>
              </Flex>
              <Flex align="center" my="2" width="25%">
                <Text width="95%">Correct Point:</Text>
                <Input
                  border="2px"
                  borderColor="gray.400"
                  placeholder="+ve point"
                  value={correctPoint}
                  onChange={handleCorrectPointsInput}
                />
              </Flex>
              <Flex align="center" my="2" width="30%">
                <Text width="100%">Negative Point:</Text>
                <Input
                  border="2px"
                  borderColor="gray.400"
                  placeholder="-ve point"
                  value={negativePoint}
                  onChange={handleNegativePointsInput}
                />
              </Flex>
            </Flex>
          </>
        )}
        <Flex justify="space-between">
          {questionType === "objective" && (
            <Flex align="center" my="4" width="45%">
              <Text width="25%">Subject:</Text>
              <Select
                placeholder="Select Subject Type"
                border="2px"
                borderColor="gray.400"
                onChange={handlesubjectInput}
              >
                <option value="english">English</option>
                <option value="math">Math</option>
                <option value="science/other">Science/Other</option>
                <option value="gk">GK</option>
              </Select>
            </Flex>
          )}
          <Flex align="center" my="4" width="45%">
            <Text width="35%">Test Set Type:</Text>
            <Select
              placeholder="Select Test Set"
              border="2px"
              borderColor="gray.400"
              onChange={handleTestSetTypeInput}
            >
              {activeTests?.map((testType) => {
                return (
                  <option key={testType._id} value={testType._id}>
                    {testType.name}
                  </option>
                );
              })}
            </Select>
          </Flex>
        </Flex>
        <Button p="8" mt="2" fontSize="xl" onClick={handleSubmit}>
          Submit
        </Button>
      </Flex>
    </Flex>
  );
};

export default QuestionsUpload;
