/* eslint-disable */
import { useState, useEffect } from "react";
import axios from "axios";
import cn from "classnames";
import { PurpleButton } from "../utils/buttons";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const styles = require("./Home.module.css");

const Navbar = ({ utmMedium }: { utmMedium: string | null }) => {
  const [testStatus, setTestStatus] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const { status, data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/lancet/6150103cc3f82e5d584c3330/detail`
        );
        if (status === 200) {
          setTestStatus(data.lancet.testStatus);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <div className={cn(styles["lancet__navbar"])}>
        <a href="https://www.learnhat.com" target="_blank">
          <img
            src="https://res.cloudinary.com/learnhat/image/upload/v1628258183/website_images/logo_learnhat.png"
            alt="learnhat-logo"
          />
        </a>
        {testStatus && (
          <div className={cn(styles["lancet__navbar-login"])}>
            <h2>Login here for LANCET Test </h2>
            <Link to="/test">
              <PurpleButton>Login</PurpleButton>
            </Link>
          </div>
        )}
      </div>
      {/* {utmMedium === "RETPMKY" || pathname === "/pmkvy" ? "" : (
        <div className={cn(styles["lancet-infobar"])}>
          <h2>Registrations for this LANCET has been closed for now.</h2>
          <h3>Stay tuned for next LANCET at 26/09/21 </h3>
        </div>
      )} */}
    </>
  );
};

export default Navbar;
