import HomeAbout from "./HomeAbout";
import HomeHero from "./HomeHero";
import FAQs from "./FAQs";
import Navbar from "./Navbar";

const Home = ({
  utmMedium,
  utmSource,
  utmCampaign,
}: {
  utmMedium: string | null;
  utmSource: string | null;
  utmCampaign: string | null;
}) => {
  return (
    <div>
      <Navbar utmMedium={utmMedium} />
      <HomeHero utmMedium={utmMedium} />
      <HomeAbout
        utmMedium={utmMedium}
        utmSource={utmSource}
        utmCampaign={utmCampaign}
      />
      <FAQs />
    </div>
  );
};

export default Home;
