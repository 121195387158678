import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { LanguageProvider } from "./language";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import { LoadingProvider } from "./components/utils/loader"
import { AdminAuthProvider } from "./components/admin"
import { TestAuthProvider, QuizProvider } from "./components/lancetTest"

import reportWebVitals from "./reportWebVitals";

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <LanguageProvider>
        <LoadingProvider>
          <Router>
            <AdminAuthProvider>
              <TestAuthProvider>
                <QuizProvider>
                  <App />
                </QuizProvider>
              </TestAuthProvider>
            </AdminAuthProvider>
          </Router>
        </LoadingProvider>
      </LanguageProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
