/* eslint-disable */ 

import React, { createContext, useContext, useState } from "react";
import { LanguageContextState, LanguageData } from "./language.types";
import { languageContent } from "./language-content";

const initialState: LanguageContextState = {
  currentLanguage: "eng",
  languageData: languageContent["eng"],
  setCurrentLanguage: () => null,
};

const LanguageContext = createContext<LanguageContextState>(initialState);

export const LanguageProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>("eng");

  const loadData:LanguageData = languageContent["eng"]

  const [languageData, setLanguageData] = useState<LanguageData | null>({...loadData});

  return (
    <LanguageContext.Provider
      value={{ currentLanguage, setCurrentLanguage, languageData }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
