/* eslint-disable */

import { Flex, Text, Button, Link, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useQuiz } from "../quiz";
import { useTestAuth } from "../auth";
import { useLoading } from "../../utils/loader";
import axios, { AxiosResponse } from "axios";
import { saveAs } from "file-saver";

export default function ShowResults({ setShowResults, setShowRules }: any) {
  const { quizState } = useQuiz();

  const { analytics, scoreData, userAnswers, testId, testDate } = quizState;

  const { student } = useTestAuth();

  const { setLoadingStatus } = useLoading();
  const toast = useToast();

  const createAndDownloadCertificate = () => {
    setLoadingStatus("loading");
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/certificate/create-certificate`,
        {
          name: student && student.name,
          email: student && student.email,
          date: testDate,
          marks: scoreData.score,
          outOfMarks: scoreData.outOfScore,
          percentage: Math.round(
            (scoreData.score / scoreData.outOfScore) * 100
          ),
        },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "certificate.pdf");
        setLoadingStatus("done");
      })
      .catch((err) => {
        console.log(err);
        setLoadingStatus("done");
        toast({
          title: "Something went wrong!!",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    (async function () {
      setLoadingStatus("loading");
      try {
        const { status } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/student/${student?._id}/submit-test-score`,
          {
            testId,
            score: scoreData.score,
            submissionData: userAnswers,
            outOfScore: scoreData.outOfScore,
            timeTaken: scoreData.timeTaken,
            percentage: Math.round(
              (scoreData.score / scoreData.outOfScore) * 100
            ),
            testDate
          }
        );
        if (status === 200) {
          setLoadingStatus("done");
          toast({
            title: "Test result submitted!!",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      } catch (error) {
        setLoadingStatus("done");
        toast({
          title: "Something went wrong!!",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    })();
  }, []);

  async function addTestTaken() {
    try {
      const { status }: AxiosResponse<any> = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/student/${student?._id}/add-test`,
        {
          testId,
        }
      );
      if (status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    addTestTaken();
  }, []);

  return (
    <Flex direction="column" w="100%" h="100vh" align="center" p="4">
      <Flex
        w={{ base: "100%", xl: "40%" }}
        direction="column"
        p={8}
        borderRadius="lg"
        mt="10"
        border="2px"
        borderColor="black"
      >
        <Text fontWeight="500" textAlign="center">
          {student && student.name}
        </Text>
        <Text textAlign="center" fontSize="xl" mt="4">
          Results
        </Text>
        <Flex
          w="100%"
          p={4}
          justify="space-between"
          bg={"green.200"}
          my={2}
          color="black"
          borderRadius="lg"
        >
          <Text>Correct Answers: </Text>
          <Text>{analytics.correct}</Text>
        </Flex>
        <Flex
          w="100%"
          p={4}
          justify="space-between"
          bg={"red.200"}
          my={2}
          color="black"
          borderRadius="lg"
        >
          <Text>Wrong Answers: </Text>
          <Text>{analytics.incorrect}</Text>
        </Flex>
        <Flex
          w="100%"
          p={4}
          justify="space-between"
          bg={"yellow.200"}
          my={2}
          color="black"
          borderRadius="lg"
        >
          <Text>Not Attempted: </Text>
          <Text>{analytics.unattempted}</Text>
        </Flex>
        <Flex
          w="100%"
          p={4}
          justify="space-between"
          bg={"blue.200"}
          my={2}
          color="black"
          borderRadius="lg"
        >
          <Text>Score: </Text>
          <Text>{`${scoreData.score}/${scoreData.outOfScore}`}</Text>
        </Flex>
        <Flex direction="column" width="100%" align="center" mt="4">
          <Text>Time taken:{scoreData.timeTaken}</Text>
          <Text textAlign="center" mt="4" fontWeight="500">
            You will get your result & participation certificate by clicking the
            button below{" "}
          </Text>
          <Button onClick={createAndDownloadCertificate}>
            Get Certificate
          </Button>
        </Flex>
      </Flex>
      <Link to="/test" as={RouterLink} mt="4">
        <Button p="8" fontSize="xl">
          Go to Home
        </Button>
      </Link>
    </Flex>
  );
}
