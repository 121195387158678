import { Flex, Input, Text, Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTestAuth } from "../auth";
import { verifyOtp } from "../../utils/auth-helper-functions";
import { useLoading } from "../../utils/loader";

const StudentVerifyOTP = () => {
  const [otp, setOTP] = useState("");

  const toast = useToast();
  const { login, setLogin, setToken, setStudentData, setStudentId } =
    useTestAuth();
  const { setLoadingStatus } = useLoading();

  const navigate = useNavigate();
  const { state }: any = useLocation();

  login &&
    navigate(state !== null && state?.from ? `/test/${state.from}` : "/test");

  async function loginClickHandler(otp: string) {
    setLoadingStatus("loading");
    const { loginStatus, token, studentId } = await verifyOtp(otp);
    if (loginStatus) {
      setLoadingStatus("done");
      setLogin(true);
      setToken(token);
      setStudentId(studentId);
      setStudentData();
      toast({
        title: "Login Successfull",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    } else {
      setLoadingStatus("done");
      toast({
        title: "Invalid OTP",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }

  return (
    <Flex w="100%" h="90vh" justify="center" align="center">
      <Flex
        w={{ base: "90%", xl: "30%" }}
        bg="blue.200"
        direction="column"
        p="5"
        align="center"
        borderRadius="lg"
      >
        <Text fontSize="lg">Login With OTP</Text>
        <Text fontSize="md" mt="3">
          Enter OTP
        </Text>
        <Input
          type="number"
          placeholder="OTP"
          maxLength={10}
          my="3"
          onChange={(e) => setOTP(e.target.value)}
          bg="white"
        />
        <Button
          mt="4"
          w={{ base: "50%", xl: "30%" }}
          onClick={() => loginClickHandler(otp)}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};

export default StudentVerifyOTP;
