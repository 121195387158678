import { Route, Navigate } from "react-router-dom"
import { useTestAuth } from "../auth"

const ProtectedTestRoute = ({ path, element, ...props }:any) => {

    const { login } = useTestAuth()

    return login ? (
      <Route {...props} element={element} path={path} />
    ) : (
      <Navigate replace to="/test/login" state={{ from: path }} />
    );
}

export default ProtectedTestRoute
