import { Flex, Text, Button } from "@chakra-ui/react";

export default function ShowRules({ setShowRules, setStartQuiz }: any) {
  return (
    <Flex
      w={{ base: "90%", xl: "50%" }}
      bg={"blue.200"}
      borderRadius="xl"
      p={4}
      direction="column"
      justify="center"
      align="center"
    >
      <Text fontSize="xl" textAlign="center">
        Rules
      </Text>
      {[
        "This quiz contains 20 questions (19 MCQ + 1 Subjective)",
        "Each correct answer will get you 5 points.",
        "There is no negative point.",
        "Question once skipped cannot be attempted again.",
        "There will be time limit of 30 minutes for this test.",
        "Do not press back or reload the page. You have just 1 chance for this test",
        "All the Best !!",
      ].map((rule) => (
        <Text fontSize={{ base: "sm", xl: "md" }} key={rule.substr(0 , 10)} textAlign="center" mb={2}>
          {rule}
        </Text>
      ))}
      <br />
      <Button
        mx="auto"
        w="50%"
        m={[3]}
        onClick={() => {
          setStartQuiz(true);
          setShowRules(false);
        }}
      >
        Start Quiz
      </Button>
    </Flex>
  );
}
