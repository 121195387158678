import React, { useState, useContext, createContext } from "react"
import { LoadingContextState } from "./loading.types"

const initialState : LoadingContextState = {
    loadingStatus: "done",
    setLoadingStatus: () => null
}

const LoadingContext = createContext<LoadingContextState>(initialState)

export const LoadingProvider = ({ children }: React.PropsWithChildren<{}>) => {

    const [loadingStatus, setLoadingStatus] = useState<string>("done")

    return <LoadingContext.Provider value={{ loadingStatus, setLoadingStatus }}>
        {children}
    </LoadingContext.Provider>
}

export const useLoading = () => useContext(LoadingContext)