import { Flex, Input, Text, Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTestAuth } from "../auth";
import { loginStudent } from "../../utils/auth-helper-functions";
import { useLoading } from "../../utils/loader";

const StudentLogin = () => {
  const [mobileNumber, setMobileNumber] = useState("");

  const toast = useToast();
  const { login, setToken } = useTestAuth();
  const { setLoadingStatus } = useLoading();

  const navigate = useNavigate();
  const { state }: any = useLocation();

  login &&
    navigate(state !== null && state?.from ? `/test/${state.from}` : "/test");

  async function loginClickHandler(mobileNumber: string) {
    setLoadingStatus("loading");
    const { otpStatus, token, message } = await loginStudent(mobileNumber);
    if (otpStatus) {
      setLoadingStatus("done");
      setToken(token);
      toast({
        title: message,
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      navigate("/test/verify-otp");
    } else {
      setLoadingStatus("done");
      navigate("/");
      toast({
        title: message,
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <Flex w="100%" h="90vh" justify="center" align="center">
      <Flex
        w={{ base: "90%", xl: "30%" }}
        bg="blue.200"
        direction="column"
        p="5"
        align="center"
        borderRadius="lg"
      >
        <Text fontSize="lg">Login With OTP</Text>
        <Text fontSize="md" mt="3">
          Enter Mobile Number
        </Text>
        <Input
          type="number"
          placeholder="Mobile Number"
          maxLength={10}
          my="3"
          onChange={(e) => setMobileNumber(e.target.value)}
          bg="white"
        />
        <Button
          mt="4"
          w={{ base: "50%", xl: "30%" }}
          onClick={() => loginClickHandler(mobileNumber)}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};

export default StudentLogin;
