import {
  Flex,
  Input,
  Text,
  InputGroup,
  InputRightElement,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAdminAuth } from "../auth";
import { loginAdmin } from "../../utils/auth-helper-functions";
import { useLoading } from "../../utils/loader"

export default function AdminLogin() {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const toast = useToast();
  const { login, setLogin, setToken } = useAdminAuth();
  const { setLoadingStatus } = useLoading();

  const navigate = useNavigate();
  const { state }: any = useLocation();

  login &&
    navigate(state !== null && state?.from ? `/admin/${state.from}` : "/admin");

  async function loginClickHandler(username: string, password: string) {
    setLoadingStatus("loading");
    const { loginStatus, token } = await loginAdmin(username, password);
    if (loginStatus) {
      setLoadingStatus("done");
      setLogin(true);
      setToken(token);
      toast({
        title: "Login Successfull",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    } else {
      setLoadingStatus("done");
      toast({
        title: "Invalid credentials",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }

  return (
    <Flex w="100%" h="90vh" justify="center" align="center">
      <Flex
        w={{ base: "90%", xl: "30%" }}
        bg="blue.200"
        direction="column"
        p="5"
        align="center"
        borderRadius="lg"
      >
        <Text fontSize="lg">Login</Text>
        <Input
          placeholder="Username"
          my="3"
          onChange={(e) => setUsername(e.target.value)}
          bg="white"
        />
        <InputGroup size="md">
          <Input
            pr="4"
            type={show ? "text" : "password"}
            placeholder="Enter password"
            onChange={(e) => setPassword(e.target.value)}
            bg="white"
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {show ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        </InputGroup>
        <Button
          mt="4"
          w={{ base: "50%", xl: "30%" }}
          onClick={() => loginClickHandler(username, password)}
        >
          Login
        </Button>
      </Flex>
    </Flex>
  );
}
