import { Flex, Button, Link, Text } from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"

const AdminHome = () => {
    return (
      <div>
        <Flex
          w="100%"
          h="90vh"
          justify="center"
          align="center"
          direction="column"
          p="4"
        >
          <Text fontSize="xl" mb="3" fontWeight="500">
            Welcome Lancet Admin
          </Text>
          <Flex
            bg="twitter.200"
            w={{ base: "100%", xl: "30%" }}
            h="200px"
            borderRadius="xl"
            justify="center"
            align="center"
            direction="column"
          >
            <Text fontSize="md" mb="3" fontWeight="500">
              Start Uploading Content for LANCET
            </Text>
            <Link to="/admin/cms" as={RouterLink}>
              <Button>Go to CMS</Button>
            </Link>
          </Flex>
        </Flex>
      </div>
    );
}

export default AdminHome
