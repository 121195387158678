import cn from "classnames"
import { useLanguage } from "../../language"
import { useLocation } from "react-router-dom"

const styles = require("./Home.module.css");

const HomeHero = ({ utmMedium }: { utmMedium: string | null }) => {

    const { languageData } = useLanguage();
    const { pathname } = useLocation();

    const { title_part1, title_part2, code_name, worth_rupee,details } =
      languageData || { title_part1: "", title_part2: "", code_name: "",worth_rupee: "", details: null }

    return (
      <div>
        <section className={cn(styles["lancet"])}>
          <div className={cn(styles["lancet__hero"])}>
            <div className={cn(styles["lancet__hero-img"])}></div>
            <div className={cn(styles["lancet__details"])}>
              <h1 className={cn(styles["lancet__heading"])}>
                {title_part1}
                <br />
                {title_part2}
                <br />
                <span className="color--blue">({code_name})</span>
              </h1>
              <div className={cn(styles["lancet__money"])}>
                <h2>{worth_rupee}</h2>
                <img
                  src="https://res.cloudinary.com/learnhat/image/upload/v1629477949/website_images/icons/scho-price-icon.png"
                  alt="money-pool"
                />
              </div>
              <p className={cn(styles["lancet__timings"])}>
                {details && details["date_time"]["date_text"]}:
                <b className="color--blue m-05">
                  {utmMedium === "RETPMKY" || pathname === "/pmkvy"
                    ? "17-09-21"
                    : details && details["date_time"]["date"]}
                </b>
                {details && details["date_time"]["time_text"]}
                <b className="color--blue m-05">
                  {details && details["date_time"]["time"]}
                </b>
                <span className="ml-1">
                  {details && details["for"]["text"]}:
                  <b className="color--blue m-05">
                    {details && details["for"]["value"]}
                  </b>
                </span>
              </p>
            </div>
          </div>
        </section>
      </div>
    );
}

export default HomeHero
