import { useState } from "react";
import { IconButton } from "../utils/buttons";
import { useLanguage } from "../../language";
import cn from "classnames";

const styles = require("./Home.module.css");

const plusIcon = (
  <img
    src="https://res.cloudinary.com/learnhat/image/upload/v1629477949/website_images/icons/faq-plus.png"
    alt="plus-icon"
  />
);

const minusIcon = (
  <img
    src="https://res.cloudinary.com/learnhat/image/upload/v1629477949/website_images/icons/faq-minus.png"
    alt="minus-icon"
  />
);

const FAQs = () => {
  const { languageData } = useLanguage();

  const { faq_title, faqs, t_and_c } = languageData || {
    faq_title: null,
    faqs: [],
    t_and_c: null,
  };

  return (
    <section className={cn(styles["lancet__faq"])}>
      <div className={cn(styles["lancet__faq-title"])}>
        <div className={cn(styles["lancet__faq-icon"])}>
          <img
            src="https://res.cloudinary.com/learnhat/image/upload/v1629477950/website_images/icons/faq-icon.png"
            alt="faq-icon"
          />
        </div>
        <h1 className={cn(styles["lancet__faq-heading"])}>
          {faq_title && faq_title.top}
          <br />
          <span className="color--blue">{faq_title && faq_title.bottom}</span>
        </h1>
      </div>
      <div className={cn(styles["lancet__faq-container"])}>
        {faqs.map(({ question, answer }, index) => {
          return <FAQCard key={`question${index}`} question={question} answer={answer} index={index} />;
        })}
      </div>
      <div className={cn(styles["lancet__terms"])}>
        <h1>*{t_and_c && t_and_c.title}*</h1>
        <p>{t_and_c && t_and_c.text}</p>
      </div>
    </section>
  );
};

const FAQCard = ({
  question,
  answer,
  index,
}: {
  question: string;
  answer: string[];
  index: number;
}) => {
  const [showFaqCard, setShowFaqCard] = useState<boolean>(false);

  function handleButtonClick(e: any) {
    setShowFaqCard(!showFaqCard);
  }

  return (
    <div className="lancet__faq-card" onClick={handleButtonClick}>
      <div className={cn(styles["lancet__faq-question"])}>
        <div className={cn(styles["lancet__faq-question-container"])}>
          <span className={cn(styles["lancet__faq-qno"])}>Q{index + 1}.</span>
          <h1 className={cn(styles["lancet__faq-question-text"])}>
            {question}
          </h1>
        </div>
        <IconButton onClick={handleButtonClick}>
          {showFaqCard ? minusIcon : plusIcon}
        </IconButton>
      </div>
      <div
        className={cn(
          styles["lancet__faq-answer"],
          showFaqCard && styles["answer-active"]
        )}
      >
        {answer.map((ans, index) => {
          return <p key={`ans${index}`} className={cn(styles["lancet__faq-answertext"])}>{ans}</p>;
        })}
      </div>
    </div>
  );
};

export default FAQs;
