export const languageContent = {
  eng: {
    title_part1: "Learnhat National",
    title_part2: "Scholarship Test",
    code_name: "LANCET",
    worth_rupee: "Scholarships worth ₹ 1 crore on offer*",
    details: {
      date_time: {
        date_text: "Test Date",
        date: "8th Oct'21",
        time_text: "at",
        time: "5:00 PM",
      },
      for: {
        text: "Class",
        value: "1-10",
      },
    },
    form: {
      heading: "Enroll For Scholarship Now",
      name_placeholder: "Name",
      email_placeholder: "Email",
      number_placeholder: "Phone Number",
      class_placeholder: "Select Class",
      state_placeholder: "State",
      class_options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      cta: "Apply",
      t_and_c: {
        title: "Customer Agreement and Consent",
        checkbox_text: "T&C and Privacy Policy",
        links: {
          text_part1: "I agree to the",
          link_tc: "Terms and Conditions",
          text_part2: "and",
          link_p: "Privacy Policy",
          text_part3: "of LearnHat",
        },
      },
    },
    why_lancet: {
      heading: {
        title: "Why you should give",
        highlight: "LANCET",
      },
      cards: [
        {
          icon: "scholarship",
          top_text: {
            highlight_purple: "Scholarships",
            highlight__black: "",
          },
          bottom_text: {
            normal: "upto",
            highlight_black: "100% *",
            highlight_purple: "",
          },
        },
        {
          icon: "masterclass",
          top_text: {
            highlight_black: "Free masterclass",
          },
          bottom_text: {
            normal: "of your",
            highlight_purple: "choice*",
          },
        },
        {
          icon: "certificate",
          top_text: {
            normal: "Participation",
            highlight_purple: "Certificate",
          },
          bottom_text: {
            normal: "for all",
            highlight_black: "Participants",
          },
        },
        {
          icon: "goodies",
          top_text: {
            normal: "Exciting",
            highlight_black: "Goodies",
          },
          bottom_text: {
            normal: "signed by our",
            highlight_purple: "CEO*",
          },
        },
      ],
      offers: {
        title: "Offers on Scholarship",
        numbers: ["100%", "50%", "30%", "25%"],
      },
    },
    about_lancet: {
      heading: {
        normal: "About",
        highlight_text: "LANCET ' 21",
      },
      cards: [
        {
          icon: "test",
          title: "Test Type",
          data: "Online Test",
          color: "blue",
        },
        {
          icon: "agegroup",
          title: "Class",
          data: "1 - 10",
          color: "yellow",
        },
        {
          icon: "date",
          title: "Test Date",
          data: "8th Oct 2021",
          color: "purple",
        },
      ],
    },
    faq_title: {
      top: "Frequently Asked Questions For",
      bottom: "LANCET ' 21",
    },
    faqs: [
      {
        question: "What are the benefits to students?",
        answer: [
          "1. Analyse your strengths and key areas of improvement.",
          "2. Benchmark themselves against other students at All-India Level.",
          "3. Participation certificate for all test takers.",
          "4. Better preparation for future exams.",
        ],
      },
      {
        question: "Registration Fee",
        answer: [
          "LANCET is a free online test open to age groups of 6-16 years.",
        ],
      },
      {
        question: "How to Apply?",
        answer: ["Register online on this website with a valid mobile number."],
      },
      {
        question: "Test Pattern and Syllabus",
        answer: [
          "Test Duration: 30 minutes",
          "Total Number of Questions: 21",
          "Mode: Online Test",
          "Syllabus: English, Math, Science, GK, Aptitude & Others",
        ],
      },
      {
        question: "Marking Scheme",
        answer: ["+1 for each right answer, No negative marking"],
      },
      {
        question: "Rewards",
        answer: [
          "Scholarships will be rewarded based on your performance in LANCET.",
          "Scholarships on offer:",
          "100%, 50%, 30% and 25% Scholarships available",
        ],
      },
      {
        question: "How to take LANCET?",
        answer: [
          "LANCET has to be taken online. The test will be conducted on 29th August 2021, at this platform.",
          "To login, students must fill up their login credentials and take the test.",
          "Students must submit only when they have attempted the complete test.",
        ],
      },
      {
        question: "System Requirements for LANCET",
        answer: [
          "LANCET can be taken on any laptop, desktop, mobile phone or tablet. For best user experience, we recommend using a modern browser.",
          "Please check your device, browser and your internet connection to make sure you get a hassle free test experience.",
        ],
      },
      {
        question:
          " I am an existing learner at LearnHat, can I appear for LANCET?",
        answer: [
          "Yes. All eligible students, even LearnHat students, can appear for LANCET. But the scholarship availed can only be used for enrolling in fresh courses.",
        ],
      },
      {
        question: "Can I reschedule my test?",
        answer: ["No, you cannot."],
      },
      {
        question: "When will the results be announced?",
        answer: [
          "Results for LANCET will be announced on 15th September, 2021 on LearnHat’s official website (www.learnhat.com)",
        ],
      },
      {
        question: "Can I appear for LANCET in any other language?",
        answer: ["For now, LANCET will be available only in English."],
      },
      {
        question: "When can I avail my Scholarship?",
        answer: ["You can avail your Scholarship within 15 days of results"],
      },
      {
        question: "What if I have a further query?",
        answer: ["Please send your queries to info@learnhat.com."],
      },
    ],
    t_and_c: {
      title: "T&C",
      text: "LearnHat’s decision on Scholarship is final. LANCET is based on Internal parameters of LearnHat. The applicant and the applicant's parents agree that LearnHat shall not be held responsible for any loss/damage, caused to the applicant or the applicant's parent, due to the usage or placing reliance on LANCET or LANCET results for any decision being made by the applicant's parent or the applicant. Applicant and applicant's parents agree that LearnHat may use the data submitted by the applicant for its business.",
    },
  },
};
