import { Outlet } from "react-router-dom"
import { TestNavbar } from "../lancetTest"

const LancetTest = () => {
    return (
      <>
        <TestNavbar />
        <Outlet />
      </>
    );
}

export default LancetTest
