import cn from "classnames";
const styles = require("./Buttons.module.css");

const BlueButton = ({ children, className }: {children: JSX.Element, className?: string}) => {
  return (
    <button className={cn(styles.btn, styles["btn--blue"], className)}>
      {children}
    </button>
  );
};

const PurpleButton = ({
  btnType,
  children,
  className,
  id,
  type,
  onClick,
  disabled
}: {
  btnType?: string;
  children: any;
  className?: string;
  id?: string;
  type?: | "submit" | "reset" | "button";
  onClick?: (e: any) => void;
  disabled?: boolean
}) => {
  return (
    <button
      type={type}
      id={id}
      className={cn(
        styles.btn,
        styles["btn-purple"],
        btnType && styles[btnType],
        className,
        disabled && styles["btn--gray"]
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const IconButton = ({
  children,
  className,
  onClick,
}: {
  children: JSX.Element;
  className?: string;
  onClick: (e: any) => void;
}) => {
  return (
    <button className={cn(styles.btnicon, className)} onClick={onClick}>
      {children}
    </button>
  );
};

const IconWithTextButton = ({
  children,
  onClick,
  onHover,
  className,
}: {
  children: JSX.Element;
  onClick: () => null;
  onHover: () => null;
  className?: string;
}) => {
  return (
    <button
      onClick={onClick}
      onMouseOver={onHover}
      onMouseOut={onHover}
      className={cn(styles["btn-iconwithtext"], className)}
    >
      {children}
    </button>
  );
};

export { BlueButton, PurpleButton, IconButton, IconWithTextButton };
